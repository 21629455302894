window.DGS.OnLoad.getInstance().register(function () {
  // SETUP GLOBAL A TAG ANCHOR SCROLLING
  $("a").each(function () {
    var href = $(this).attr("href");
    if (href !== undefined && href.indexOf("#") !== -1) {
      $(this).on("click", function (e) {
        var href = $(this).attr("href"),
          targetName = href.substr(href.indexOf("#") + 1),
          $target = $('a[name="' + targetName + '"]').length ? $('a[name="' + targetName + '"]') : $('div[id="' + targetName + '"]'),
          position = $target.position(),
          top = position ? position.top : null,
          $mainMenu = $(".header-desktop-top"),
          $submenu = $mainMenu.length ? $mainMenu : $(".component.richtext-submenu"),
          mainMenuHeight = $submenu.length ? $submenu.outerHeight() : 0;
        if (top != null) {
          e.preventDefault();
          $("html, body").animate({
            scrollTop: top - mainMenuHeight
          }, 600);
        }
      });
    }
  });
});

// comparison table

$(".comparison-table ul").on("click", "li", function () {
  var pos = $(this).index() + 2;
  $("tr").find("td:not(:eq(0))").hide();
  $("td:nth-child(" + pos + ")").css("display", "table-cell");
  $("tr").find("th:not(:eq(0))").hide();
  $("li").removeClass("active");
  $(this).addClass("active");
});

// Initialize the media query
var mediaQuery = window.matchMedia("(min-width: 780px)");

// Add a listen event
mediaQuery.addListener(collapse);

// Function to do something with the media query
function collapse(mediaQuery) {
  if (mediaQuery.matches) {
    $(".sep").attr("colspan", 6);
  } else {
    $(".sep").attr("colspan", 2);
  }
}

// On load
collapse(mediaQuery);

// Temporary solution for disabling footer animation showing with delay
$(function () {
  $("#footer").addClass("animate");
});