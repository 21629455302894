(function ($, DGS, moment) {
  "use strict";

  var setHeights = function setHeights(row, selectors, node) {
    var _loop = function _loop() {
      var tempArray = selectors.slice(i, i + row);
      var tempHeight = null;
      tempArray.each(function () {
        var _that = $(this);
        var nodeToAlign = $(node, _that);
        nodeToAlign.attr('style', '');
        if (nodeToAlign.height() > tempHeight) {
          tempHeight = nodeToAlign.height();
        }
      });
      if (tempHeight) {
        tempArray.find(node).height(tempHeight);
      }
    };
    for (var i = 0, j = selectors.length; i < j; i += row) {
      _loop();
    }
  };
  DGS.alignElements = function (row, wrapper, itemsArray) {
    var selector = wrapper;
    if (typeof selector === 'string') {
      selector = $(wrapper);
    }
    itemsArray.forEach(function (element) {
      setHeights(row, selector, element);
    });
  };
})(jQuery, window.DGS, window.moment);