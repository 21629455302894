import { convertDate } from "../utils.js";
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    $(".component.latest-blog-posts a").each(function () {
      var hrefAttr = $(this).attr("href");
      var location = window.location.href.replace(/^(?:\/\/|[^/]+)*\//, "");
      if (hrefAttr === location) {
        $(this).parent("li").remove();
      }
      var $newsDate = $(".news-date", $(this));
      // Moves the date to after the title
      $newsDate.insertAfter($(".news-title", $(this)), null);
    });
    if (!$(".component.latest-blog-posts ul > li").length) {
      $(".component.latest-blog-posts").hide();
    }
    var newsDate = document.querySelectorAll(".latest-blog-list .news-date");
    newsDate.forEach(function (newsDate) {
      var dateFormat = newsDate.textContent;
      var convertedDate = convertDate(dateFormat);
      newsDate.textContent = convertedDate;
    });
    var newsLink = document.querySelectorAll(".latest-blog-list .news-link");
    newsLink.forEach(function (el) {
      el.querySelector("span").innerText = "Read more >";
    });
  });
  DGS.Resizer.getInstance().register(function () {
    var row = 1;
    if ($(window).width() > 1200) {
      row = 4;
    } else if ($(window).width() > 991) {
      row = 2;
    }
    DGS.alignElements(row, ".latest-blog-list > #blog-list > li", [".news-title", ".news-text"]);
  });
})(jQuery, window.DGS);