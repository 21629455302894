import { convertDate } from "../utils.js";
(function ($, DGS, moment) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $(".component.related-blogs").each(function () {
      var $this = $(this);
      var $relatedPosts = $(".component-content ul > li > div", $this);
      $relatedPosts.each(function (i, relatedPost) {
        var $link = $(".news-link .link", $(relatedPost)).last();
        $(relatedPost).click(function (e) {
          if (!$(e.target).hasClass("link")) {
            // jQuery click does not work on links
            $link[0].click();
          }
        });
      });
      setTimeout(function () {
        $this.addClass("component-ready animate");
      }, 500);
    });
    $(".component.related-blogs ul > li > div").each(function () {
      var $newsDate = $(".news-date", $(this));
      $newsDate.insertAfter($(".news-title", $(this)), null);
    });
    $(".component.related-blogs a").each(function () {
      var hrefAttr = $(this).attr("href");
      var location = window.location.href.replace(/^(?:\/\/|[^/]+)*\//, "");
      if (hrefAttr === location) {
        $(this).parent("li").remove();
      }
    });
    if ($(".component.related-blogs .component-content .related-title").length && $(".component.rich-text.related-title").length) {
      $(".component.rich-text.related-title").hide();
    }
    if (!$(".component.related-blogs ul > li").length) {
      $(".component.related-blogs").hide();
    }
  });
  var newsDate = document.querySelectorAll(".component.related-blogs .component-content .news-date");
  newsDate.forEach(function (newsDate) {
    var dateFormat = newsDate.textContent;
    var convertedDate = convertDate(dateFormat);
    newsDate.textContent = convertedDate;
  });
  var newsLink = document.querySelectorAll(".component.related-blogs .component-content .news-link");
  newsLink.forEach(function (el) {
    el.querySelector("a").innerText = "Read more >";
  });
  DGS.Resizer.getInstance().register(function () {
    var row = 1;
    if ($(window).width() > 1200) {
      row = 3;
    } else if ($(window).width() > 991) {
      row = 2;
    }
    DGS.alignElements(row, ".component.related-blogs ul > li > div", [".news-title", ".news-text"]);
  });
})(jQuery, window.DGS, window.moment);