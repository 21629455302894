import { convertDate } from "../utils.js";
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    if ($(".blog").length > 0) {
      var blogDate = document.querySelector(".blog-date").textContent;
      var convertedDate = convertDate(blogDate);
      blogDate = convertedDate;
      var blogAuthor = document.querySelector(".blog-author");
      blogAuthor.innerHTML = blogAuthor.innerHTML.replace(/<\/span>\s+/, "</span>");
      var authorSpan = blogAuthor.querySelector("span");
      authorSpan.textContent = authorSpan.textContent.trim();
      var authorTitle = document.querySelector(".blog-author-title");
      if (authorTitle != null && authorTitle.innerText.length > 0) {
        blogAuthor.innerHTML += ", ".concat(authorTitle.innerText, " on <span class=\"blog-date-value\">").concat(blogDate, "</span>");
      } else {
        blogAuthor.innerHTML += " on <span class=\"blog-date-value\">".concat(blogDate, "</span>");
      }
      $(".blog-date").remove();
      $("#linkAuthor").remove();
      $(".blog-author").eq(1).remove();
    }
  });
})(jQuery, window.DGS);