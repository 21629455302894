import { WDHAccordionSpot } from "../../../../wdh-feature/wdh-accordion-spot";
DGS.OnLoad.getInstance().register(function () {
  var wdhAccordionSpot = new WDHAccordionSpot(".accordion-spot");
  wdhAccordionSpot.run();
  var accordionSpots = document.querySelectorAll(".accordion-spot-element");
  accordionSpots.forEach(function (spot) {
    var expandContent = spot.querySelector(".accordion-spot-element__content");
    var headerElement = spot.querySelector(".accordion-spot-element__title");
    var headerContent = headerElement.innerHTML;
    var heading = document.createElement("h3");
    heading.innerHTML = headerContent;
    headerElement.innerHTML = "";
    headerElement.appendChild(heading);
    if (spot.classList.contains("expand")) {
      expandContent.style.height = expandContent.scrollHeight + "px";
    } else {
      expandContent.style.height = "0";
    }
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        if (mutation.attributeName === "class") {
          var currentClass = mutation.target.getAttribute("class");
          if (currentClass.includes("expand")) {
            expandContent.style.height = expandContent.scrollHeight + "px";
          } else if (currentClass.includes("shrink")) {
            expandContent.style.height = "0";
          }
        }
      });
    });
    observer.observe(spot, {
      attributes: true
    });
  });
});