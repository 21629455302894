/**
 * Created by mady on 19-04-2017.
 */
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    if (!$(".component.resource-center").length) {
      // THERE'S NO RESOURCE CENTER ON PAGE
      return;
    }
    var webservice = "/webservices/downloads.svc/downloadslist/",
      detailwebservice = "/webservices/downloads.svc/details/",
      GRID = "grid",
      LIST = "list";
    var containersContentType = [],
      containersFilters = [],
      containersFiltersMobile = [],
      contentTypes = null,
      filters = null,
      spotData = null,
      itemLayoutModel = LIST,
      selectedFilters = getFiltersFromUrl(),
      // By default get the filters from the URL
      selectedContentType = "",
      selectedLink = "",
      currentFilter = "",
      currentLayoutType;
    var fileTypes = {
        default: "e866",
        pdf: "e86a",
        img: "e89c",
        video: "e8a0",
        embed: "e8a0",
        external: "ea41"
      },
      mimeTypes = {
        PDF: "application/pdf",
        MP4: "video/mp4",
        JPEG: "image/jpeg",
        PNG: "image/png",
        BMP: "image/bmp",
        EMBED: "application/embed",
        EXTERNAL: "application/external"
      };
    var dictionary = {
      ResourceCenter: {
        filterby: "Filter by",
        viewall: "Clear filters",
        showresults: "Show results",
        contenttypes: "All Content Types",
        viewallitems: "View all {0} items",
        back: "Back to overview",
        download: "Download",
        file: "file",
        pdf: "PDF",
        img: "image",
        video: "video",
        embed: "Watch embedded content",
        loadmore: "Load more"
      }
    };
    var theLang = $("html").attr("lang");
    window.addEventListener("popstate", function (e) {
      selectedFilters = getFiltersFromUrl();
      selectedContentType = getContentTypeFromUrl();
      selectedLink = getLinkFromUrl();
      getData();
    });
    var getLinkForUrlRequest = function getLinkForUrlRequest() {
      return JSON.stringify(selectedLink);
    };
    var getFiltersForUrlRequest = function getFiltersForUrlRequest() {
      return JSON.stringify(selectedFilters);
    };
    var getContentTypeForUrlRequest = function getContentTypeForUrlRequest() {
      return selectedContentType;
    };
    var updateBrowserHistory = function updateBrowserHistory() {
      var parametersString = "?";
      if (getFiltersForUrlRequest().length) {
        parametersString += "filters=".concat(getFiltersForUrlRequest(), "&");
      }
      if (getContentTypeForUrlRequest()) {
        parametersString += "contenttype=".concat(getContentTypeForUrlRequest(), "&");
      }
      if (getLinkForUrlRequest()) {
        parametersString += "link=".concat(getLinkForUrlRequest());
      }
      if (!$("body").hasClass("on-page-editor")) {
        history.pushState(null, null, parametersString);
      }
    };
    var getLinkFromUrl = function getLinkFromUrl() {
      var linkFromUrl = DGS.getQueryParameter("link", window.location.href);
      try {
        return JSON.parse(linkFromUrl) || "";
      } catch (e) {
        console.log("getLinkFromUrl", e);
      }
      return [];
    };
    function getFiltersFromUrl() {
      // Had to transform into a function to use it above
      var filtersFromUrl = DGS.getQueryParameter("filters", window.location.href);
      try {
        return JSON.parse(filtersFromUrl) || [];
      } catch (e) {
        console.log("getFiltersFromUrl", e);
      }
      return [];
    }
    var getContentTypeFromUrl = function getContentTypeFromUrl() {
      return DGS.getQueryParameter("contenttype", window.location.href);
    };
    var getData = function getData(callback) {
      $.ajax({
        url: "".concat(webservice + spotData.dataEndpoint, "/refresh") + "/".concat(theLang),
        type: "POST",
        dataType: "json",
        data: getFiltersForUrlRequest(),
        contentType: "application/json",
        success: function success(data) {
          if (data) {
            // var firstRun = isFirstRun();
            if (data.contentTypes) {
              if (!contentTypes) {
                contentTypes = data.contentTypes;
              } else {
                updateContentTypes(data.contentTypes);
              }
            }
            if (data.filters) {
              if (!filters) {
                filters = data.filters;
              } else {
                updateFilters(data.filters);
              }
            }
            if (callback) {
              callback(); // This is mainly the buildStuff() function
              updateContentTypes(contentTypes);
            }
            checkIfThereAreFilters();
            updateSelectedFilters();
            selectedLink = getLinkFromUrl();
            if (selectedLink) {
              loadLightBox(function () {
                $(".detail-lightbox").insertAfter("#file-link-".concat(selectedLink));
              });
            }
            checkIfNoFiles();
            bindFileLinkClickEvent();
          }
        },
        error: function error(xhr) {
          console.log("error resourcecenter downloadslist", xhr);
        }
      });
    };
    var checkIfNoFiles = function checkIfNoFiles() {
      var hasFiles = false;
      if (contentTypes) {
        $.each(contentTypes, function (ind, val) {
          if (val.files.length) hasFiles = true;
        });
      }
      if (!hasFiles) {
        $(".empty-error").html(spotData.errorMessage).show();
      } else {
        $(".empty-error").hide();
      }
    };
    var checkIfThereAreFilters = function checkIfThereAreFilters() {
      if (selectedFilters.length > 0) {
        $(".clear-filters-label").addClass("show");
      } else {
        $(".clear-filters-label").removeClass("show");
      }
    };

    /**
     * Builds up the markup for the content type boxes, and all the resources files
     *
     * @returns {Array} Returns the "components" array required for the DGS.Builder to build the content
     */
    var prepareContentTypes = function prepareContentTypes() {
      var contentTypeComponents = [];
      $.each(contentTypes, function (ind, val) {
        contentTypeComponents.push($("<div class=\"content-type\" id=\"content-type-".concat(val.id, "\"><h4>").concat(val.name, "<span class=\"content-type-expander\"></h4>") + "<div class=\"content-type-files\">".concat(getFilesListString(val.files), "</div>") + '<a class="viewallitems-label link"></a>' + '<a class="rollbackup-label link"></a></div>'));
      });
      containersContentType.push({
        className: "content-type-container",
        components: contentTypeComponents
      });
      containersContentType.push({
        className: "empty-error",
        components: []
      });
      containersContentType.push({
        className: "detail-lightbox",
        components: [$('<div class="lighbox-fileimg"><img src="" /></div><h4 class="lighbox-filename"></h4>' + '<div class="lighbox-filedescription"></div><a class="lighbox-download" href="" target="_BLANK"></a><div class="lighbox-close">&#xeae3;</div>')]
      });
      return containersContentType;
    };

    /**
     * Updates the content types to match the filters
     * @param updatedContentTypes New content types
     */
    var updateContentTypes = function updateContentTypes(updatedContentTypes) {
      $(".detail-lightbox").insertAfter($(".empty-error"));
      $.each(updatedContentTypes, function (ind, contentType) {
        // Remove force locked files from the contentType
        if (!$(document.body).hasClass("user-logged-in")) {
          contentType.files = contentType.files.filter(function (file) {
            return !file.forceLock;
          });
        }
        if (contentType.files.length) {
          $("#content-type-".concat(contentType.id)).addClass("content-type");
          $("#content-type-".concat(contentType.id)).removeClass("content-type-hide");
          $(".content-type-container > .inner-container").append($("#content-type-".concat(contentType.id)));
          $("#content-type-".concat(contentType.id, " .content-type-files")).html(getFilesListString(contentType.files));
          setViewAllCounter(contentType);
          $("#content-type-".concat(contentType.id, " .content-type-count")).html(contentType.files.length);
        } else {
          $("#content-type-".concat(contentType.id)).removeClass("content-type");
          $("#content-type-".concat(contentType.id)).addClass("content-type-hide");
          $(".content-type-container").append($("#content-type-".concat(contentType.id)));
        }
      });
      contentTypes = updatedContentTypes;
      updateLayout();
    };

    /**
     * Builds an <a> with the link for the given files
     *
     * @param   files    Array of files information used to build up the required link
     * @returns {string} Returns an <a> tag based on the files information
     */
    var getFilesListString = function getFilesListString(files) {
      var filesStr = "";
      $.each(files, function (ind, file) {
        if (itemLayoutModel == LIST) {
          if (file.url.toLowerCase().indexOf("youtube") >= 0 || file.url.toLowerCase().indexOf("23video") >= 0 || file.url.toLowerCase().indexOf("youku") >= 0) {
            file.mimeType = mimeTypes.MP4;
          }
          filesStr += "<a class=\"".concat(getFileClasses(file, ind), "\" id=\"file-link-").concat(file.id, "\" target=\"").concat(file.locked ? "_self" : "_blank", "\" href=\"").concat(file.url, "\"><span class=\"content-type-icon\" >&#x").concat(getFileType(file.mimeType), ";</span>").concat(file.name, "</a>");
        } else {
          filesStr += "<a class=\"".concat(getFileClasses(file, ind), "\" id=\"file-link-").concat(file.id, "\" target=\"_self\"") + "\" href=\"".concat(file.url, "\"><div class=\"image\"><img src=\"").concat(file.image, "\" /></div><p>").concat(file.name, "</p><div class=\"cta\"></div></a>");
        }
      });
      return filesStr;
    };

    /**
     * Constructs the required html classes for the given file
     *
     * @param file
     * @param index
     * @returns {string} with the classes of the element
     */
    var getFileClasses = function getFileClasses(file, index) {
      var classes = "file-link";
      // Check if the file should be hidden
      if (index >= spotData.maxListCount) {
        classes += " file-link-hidden";
      }
      // Check if the file should open externally
      if (file.downloadDirectly || file.locked || file.mimeType === mimeTypes.EXTERNAL) {
        classes += " file-download-directly";
      }
      return classes;
    };
    var setViewAllCounter = function setViewAllCounter(contentType) {
      var $contentType = $("#content-type-".concat(contentType.id)),
        $label = $contentType.children(".viewallitems-label"),
        $backLabel = $contentType.children(".rollbackup-label");
      $label.html(dictionary.ResourceCenter.viewallitems.format(contentType.files.length));
      if (contentType.files.length > spotData.maxListCount) {
        $label.addClass("show");
      } else {
        $label.removeClass("show");
      }
      $backLabel.html(dictionary.ResourceCenter.back);
      $backLabel.removeClass("show");
    };

    /**
     * Populates the builder settings for the filters on mobile and desktop view.
     *
     * This prepares the variables containersFilters and containersFiltersMobile which build the filters.
     *
     * @NOTE: This does basically the same for mobile and desktop, but we need to separate mobile because click events
     * that should only apply on mobile. (I would also like to refactor this so is only one container)
     */
    var prepareFilters = function prepareFilters() {
      var selectFilterString = "",
        containerFiltersSelectorsMobile = [],
        containerFiltersSelectors = [];
      containersFilters.push({
        className: "filter-label"
      }, {
        className: "clear-filters-label"
      });
      containersFiltersMobile.push({
        className: "filter-label filter-label-mobile collapsed-filters"
      }, {
        className: "clear-filters-label"
      });
      $.each(filters, function (ind, filterGroup) {
        if (filterGroup.name.length) {
          selectFilterString = "<label class=\"select-label\" for=\"filter-".concat(filterGroup.id, "\">").concat(filterGroup.name, "</label><select class=\"nice-select resource-center-filter wide light\" data-filter-id=\"").concat(filterGroup.id, "\" id=\"filter-").concat(filterGroup.id, "\">").concat(getFiltersString(filterGroup, ind), "</select>");
          containerFiltersSelectors.push({
            className: "filter filter-desktop",
            id: "filter-".concat(filterGroup.id),
            components: [$(selectFilterString)]
          });
          containerFiltersSelectorsMobile.push({
            className: "filter filter-mobile",
            id: "filter-".concat(filterGroup.id),
            components: [$(selectFilterString)]
          });
        }
      });
      containerFiltersSelectorsMobile.push({
        className: "show-filter-results"
      });
      containersFiltersMobile.push({
        className: "filters-selectors",
        containers: containerFiltersSelectorsMobile
      });
      containersFilters.push({
        className: "filters-selectors",
        containers: containerFiltersSelectors
      });
      return {
        desktop: containersFilters,
        mobile: containersFiltersMobile
      };
    };
    var updateFilters = function updateFilters(updatedFilters) {
      $.each(updatedFilters, function (ind, filterGroup) {
        // Check for diff
        if (JSON.stringify(filterGroup) !== JSON.stringify(filters[ind])) {
          var $thisFilter = $(".resource-center-filter#filter-".concat(filterGroup.id));
          $thisFilter.html(getFiltersString(filterGroup, ind));
          if ($("option:selected", $thisFilter) && $("option:selected", $thisFilter).hasClass("default-filter")) {
            $thisFilter.parent().removeClass("selected");
          }
        }
      });
      DGS.Event.getInstance().trigger(DGS.Events.SELECT_UPDATED, $(".desktop-section .resource-center-filter")); // Used for the selector
      checkIfThereAreFilters();
      isShowResultsDisabled();
      updateSelectedFilters();
    };
    var updateSelectedFilters = function updateSelectedFilters() {
      var renderer = "";
      // TODO: this may not be the most optimal, it could use some more thought
      // This is tricky, but we have a list of "selectedFilters", and we need to know the id and name, which is inside an array's array.
      $.each(selectedFilters, function (ind, selectedFilter) {
        // This is the filters selected (like from the url)
        $.each(filters, function (ind, filterGroup) {
          // This is the "global" filters vars, from the getData() (comes grouped)
          $.each(filterGroup.elements, function (ind, filter) {
            // This is each filter inside the filter groups
            if (filter.id === selectedFilter) {
              renderer += "<span data-filter-id=\"".concat(filter.id, "\">").concat(filter.name, "</span>");
            }
          });
        });
      });
      $(".selected-filters .inner-container").html(renderer);
      $(".selected-filters .inner-container span").click(function () {
        updateFilterSelector();
        selectedFilters.splice(selectedFilters.indexOf($(this).attr("data-filter-id")), 1);
        updateBrowserHistory();
        getData();
      });
    };
    var updateFilterSelector = function updateFilterSelector() {
      var tmpFilters = filters;
      $.each(filters, function (ind, dropDown) {
        tmpFilters[ind].NowSelected = false;
        $.each(dropDown.elements, function (index) {
          tmpFilters[ind].elements[index].NowSelected = false;
        });
      });
      filters = tmpFilters;
    };

    /**
     * Builds up the select <option> for the filters
     *
     * @param filterGroup      Filter group to generate the different options
     * @param filterGroupIndex Index on the filters variable of the filterGroup
     * @returns {string}       Returns the whole HTML with the <options> for the filterGroup
     */
    var getFiltersString = function getFiltersString(filterGroup, filterGroupIndex) {
      var filtersFromUrl = getFiltersFromUrl(),
        filtersStr = "<option class=\"default-filter\" value=\"".concat(filterGroup.id, "\" ").concat(filterGroup.NowSelected ? "selected" : "", ">").concat(filterGroup.defaultText, "</option>"); // This is the "default"/all filter value

      $.each(filters[filterGroupIndex].elements, function (ind, filter) {
        var selected = filtersFromUrl.indexOf(filter.id) > -1;
        filtersStr += "<option value=\"".concat(filter.id, "\" ").concat(selected ? "selected " : "", ">").concat(filter.name, "</option>");
      });
      return filtersStr;
    };
    var getFileTypeString = function getFileTypeString(mimeType) {
      var fileType = dictionary.ResourceCenter.file;
      if (mimeType === mimeTypes.PDF) fileType = dictionary.ResourceCenter.pdf;else if (mimeType === mimeTypes.MP4) fileType = dictionary.ResourceCenter.video;else if (mimeType === mimeTypes.JPEG || mimeType === mimeTypes.PNG || mimeType === mimeTypes.BMP) fileType = dictionary.ResourceCenter.img;else if (mimeType === mimeTypes.EMBED) fileType = dictionary.ResourceCenter.embed;else if (mimeType === mimeTypes.EXTERNAL) fileType = dictionary.ResourceCenter.embed;
      return fileType;
    };
    var getFileType = function getFileType(mimeType) {
      var fileType = fileTypes.default;
      if (mimeType === mimeTypes.PDF) fileType = fileTypes.pdf;else if (mimeType === mimeTypes.MP4) fileType = fileTypes.video;else if (mimeType === mimeTypes.JPEG || mimeType === mimeTypes.PNG || mimeType === mimeTypes.BMP) fileType = fileTypes.img;else if (mimeType === mimeTypes.EMBED) fileType = fileTypes.embed;else if (mimeType === mimeTypes.EXTERNAL) fileType = fileTypes.external;
      return fileType;
    };
    var contentTypeShowAll = function contentTypeShowAll() {
      // hide the view all link
      var $contentType = $("#content-type-".concat(selectedContentType));
      $contentType.addClass("expanded"); // So it is expanded on mobile

      $contentType.children(".viewallitems-label").removeClass("show");

      // display hidden links within current content type
      $(".file-link", $contentType).removeClass("file-link-hidden");
      // display back link
      $contentType.children(".rollbackup-label").addClass("show");
      updateBrowserHistory();
      updateLayout();
    };
    var loadLightBox = function loadLightBox() {
      $.ajax({
        url: detailwebservice + selectedLink + "/" + theLang,
        type: "GET",
        dataType: "json",
        data: "",
        contentType: "application/json",
        success: function success(data) {
          if (data) {
            $(".detail-lightbox .lighbox-filename").html(data.name);
            $(".detail-lightbox .lighbox-filedescription").html(data.text);
            if (data.image) {
              // todo check if thumbnail exists
              $(".detail-lightbox .lighbox-fileimg img").attr("src", data.image);
              $(".detail-lightbox .lighbox-fileimg").show();
            } else {
              $(".detail-lightbox .lighbox-fileimg img").attr("src", "");
              $(".detail-lightbox .lighbox-fileimg").hide();
            }
            $(".detail-lightbox .lighbox-download").attr("href", data.url);

            // populate download link text
            if (data.mimeType === mimeTypes.EMBED) {
              $(".lighbox-download").html(getFileTypeString(data.mimeType));
              $(".lighbox-download").attr("title", $(".lighbox-download").attr("href"));
              window.DGS.Event.getInstance().trigger("link-embed-code-detected"); // activate the video spot overlay
            } else {
              $(".lighbox-download").html("".concat(dictionary.ResourceCenter.download, " ").concat(getFileTypeString(data.mimeType), " ").concat(data.fileSize));
              $(".lighbox-download").attr("title", "");
              $(".lighbox-download").unbind("click"); // revert to default click functionality for non embedded content
            }

            // show the lightbox
            $(".detail-lightbox").show(10, function () {
              if (currentLayoutType === DGS.MOBILE_LAYOUT) {
                updateLayout();
              }
            });

            // trigger event to inform that the lightbox exists
            $(".resource-center").trigger("resource-center:show-lightbox");
            updateBrowserHistory();
          } else {
            console.log("trouble getting lightbox data");
          }
        },
        error: function error(xhr, textStatus, errorThrown) {
          console.log("error resourcecenter details: ", xhr);
        }
      });
    };
    var updateLayout = function updateLayout() {
      if (itemLayoutModel == LIST) {
        updateMasonryLayout();
      } else {
        // SOMETHING
      }
    };
    var updateMasonryLayout = function updateMasonryLayout() {
      $(".content-type-container .inner-container").masonry({
        // options
        columnWidth: $(".content-type-container").outerWidth() / 2,
        itemSelector: ".content-type",
        percentPosition: true,
        horizontalOrder: true
      });
    };
    var hideLightbox = function hideLightbox($lightboxInstance) {
      $lightboxInstance.hide();
      if (currentLayoutType === DGS.MOBILE_LAYOUT) {
        updateLayout();
      }
      selectedLink = "";
      updateBrowserHistory();
    };
    var buildLoader = function buildLoader($container) {
      removeLoader($container);
      $(".component-content", $container).append('<div class="cssload-container"><div class="cssload-inner-container"></div></div>');
      var t = new TimelineMax({
        delay: 2
      });
      t.fromTo($(".cssload-container", $container), 0.3, {
        alpha: 0
      }, {
        alpha: 1,
        ease: Power2.easeIn
      });
    };
    var removeLoader = function removeLoader($container) {
      $(".cssload-container", $container).remove();
    };
    /**
     * Function to handle the click event behavior of fileLink
     * It takes in consideration the layoutType (mobile|desktop)
     *
     * TODO: This should receive the $spot variable to encapsulate the event to the component
     */
    var bindFileLinkClickEvent = function bindFileLinkClickEvent() {
      // Here is the actual click event
      $(".file-link").click(function (event) {
        if (!$(this).hasClass("file-download-directly")) {
          event.preventDefault();
          var linkId = $(this).attr("id").split("file-link-");
          if (linkId.length && linkId[1]) {
            selectedLink = linkId[1];
            if (currentLayoutType === DGS.MOBILE_LAYOUT) {
              var $detailLightbox = $(".detail-lightbox");
              if ($detailLightbox.is(":visible") && $(this).next()[0] === $detailLightbox[0]) {
                // If the lightbox is open, then close it (duh)
                hideLightbox($detailLightbox);
              } else {
                // There was no lightbox open, so just open one in the correct element
                $detailLightbox.insertAfter(this);
                loadLightBox();
              }
            } else {
              // Default (popup/dialog) behavior
              loadLightBox();
            }
            setTimeout(function () {
              var $detailLightboxContainer = $(".detail-lightbox .inner-container");
              $detailLightboxContainer.removeAttr("style");
            }, 300);
          }
        }
      });
    };
    var isShowResultsDisabled = function isShowResultsDisabled() {
      if (selectedFilters.length > 0) return; // If there are selected filters allow the user to clear them

      var areAllDefault = true;
      $.each($(".mobile-section .resource-center-filter"), function (ind, filter) {
        if ($(filter).val() != $(filter).attr("data-filter-id")) {
          areAllDefault = false;
        }
      });
      if (areAllDefault) {
        $(".show-filter-results").addClass("disabled");
      } else {
        $(".show-filter-results").removeClass("disabled");
      }
    };

    // Builds the markup (DGS.Builder), binds events, and does some magic here and there
    var buildStuff = function buildStuff($spot) {
      // This function is too big!!
      // Populate filters
      var filterContainers = prepareFilters(),
        filtersBuilderSettings = {
          mainContainer: $(".filters .inner-container", $spot),
          sections: {
            desktop: {
              containers: filterContainers.desktop
            },
            mobile: {
              containers: filterContainers.mobile
            }
          }
        };
      DGS.Builder.getInstance().build(filtersBuilderSettings);
      $(".resource-center-filter").each(function () {
        if ($(this).find("option:selected") && !$(this).find("option:selected").hasClass("default-filter")) {
          $(this).parent().addClass("selected");
        }
      });
      DGS.Event.getInstance().trigger(DGS.Events.SELECT_ADDED, $("select", $spot));

      // Populate content types
      var contentBuilderSettings = {
        mainContainer: $(".resources .inner-container", $spot),
        sections: {
          desktop: {
            // So far there is only a desktop section, reusable for the mobile with CSS
            containers: prepareContentTypes()
          }
        }
      };
      DGS.Builder.getInstance().build(contentBuilderSettings);

      // populate dictionary labels
      $(".filter-label .inner-container", $spot).html(dictionary.ResourceCenter.filterby);
      $(".clear-filters-label .inner-container", $spot).html("<a href=\"#\" class=\"clear-filters-button\">".concat(dictionary.ResourceCenter.viewall, "</a>"));
      $(".show-filter-results .inner-container", $spot).html("<a href=\"#\" id=\"show-filter-results-button\">".concat(dictionary.ResourceCenter.showresults, "</a>"));

      // build view all items labels
      if (contentTypes) {
        $.each(contentTypes, function (ind, val) {
          setViewAllCounter(val);
        });
      }
      if (selectedFilters.length > 0) {
        $(".show-filter-results").removeClass("disabled");
      } else {
        $(".show-filter-results").addClass("disabled");
      }

      // Filtering for Mobile
      $(".mobile-section .resource-center-filter", $spot).change(function () {
        isShowResultsDisabled();
      });

      // Filtering for desktop
      $(".filter-desktop .resource-center-filter", $spot).change(function () {
        currentFilter = $(this);
        var allFilterSet = false,
          tmpFilters = filters;

        // In case 'all' selected remove filter
        $.each(filters, function (ind, dropDown) {
          // In case 'all' selected remove filter
          if (dropDown.id == currentFilter.val()) {
            // 'All' filter selected
            currentFilter.parent().removeClass("selected");
            allFilterSet = true;
            $.each(dropDown.elements, function (ind, filter) {
              $.each(dropDown.elements, function (ind, filter) {
                if ($.inArray(filter.id, selectedFilters) >= 0) selectedFilters.splice($.inArray(filter.id, selectedFilters), 1);
              });
            });
            tmpFilters[ind].NowSelected = true;
            // unselect all 'regular' filters in dropdown
            $.each(dropDown.elements, function (index, filter) {
              tmpFilters[ind].elements[index].NowSelected = false;
            });
          } else {
            // 'normal filter selected'
            tmpFilters[ind].NowSelected = false;
            // check if another filter from same dropdown already set
            $.each(dropDown.elements, function (index, filter) {
              if (filter.id == currentFilter.val()) {
                currentFilter.parent().addClass("selected");
                var clearOtherFilters = false;
                // loop again and remove from selectedFilters
                $.each(dropDown.elements, function (i, filter) {
                  if ($.inArray(filter.id, selectedFilters) >= 0) {
                    selectedFilters.splice($.inArray(filter.id, selectedFilters), 1);
                    clearOtherFilters = true;
                  }
                });
                if (clearOtherFilters) {
                  // clear filters from the same dropdpwn
                  $.each(dropDown.elements, function (index, filter) {
                    tmpFilters[ind].elements[index].NowSelected = false;
                  });
                }
                tmpFilters[ind].elements[index].NowSelected = true;
              }
            });
          }
        });
        filters = tmpFilters;
        if (!allFilterSet) selectedFilters.push(currentFilter.val());
        checkIfThereAreFilters();
        updateBrowserHistory();
        getData();
      });

      // This is for the mobile filters
      $("#show-filter-results-button").click(function (e) {
        e.preventDefault();
        if ($(this).parents(".show-filter-results").hasClass("disabled")) {
          return;
        }
        var tmpFilters = [],
          tmpContentType = "";
        $(".filter-mobile .resource-center-filter", $spot).each(function () {
          var $this = $(this),
            val = $this.val();
          if (val != $this.data("filter-id") && val !== "") {
            // 'all' is not select for this filter
            if ($this.data("filter-id") == "content-type") {
              // content type filtering
              tmpContentType = val;
              return;
            }
            tmpFilters.push(val);
          }
        });
        selectedFilters = tmpFilters;
        selectedContentType = tmpContentType;
        updateBrowserHistory();
        getData();
        isShowResultsDisabled();

        // Finally collapse the filters
        $(".filter-label-mobile").toggleClass("collapsed-filters");
      });

      // Shows and hides the filters on mobile
      $(".filter-label-mobile").click(function () {
        $(this).toggleClass("collapsed-filters");
      });
      updateLayout();

      // Both desktop and mobile have a clear filters
      $(".clear-filters-button").click(function (event) {
        event.preventDefault();
        // clear all filters
        updateFilterSelector();
        selectedFilters = [];
        updateBrowserHistory();
        getData();
      });
      $(".viewallitems-label").click(function (event) {
        event.preventDefault();
        var id = $(this).parent(".content-type").attr("id").split("content-type-");
        if (id.length && id[1]) {
          selectedContentType = id[1];
          contentTypeShowAll();
        }
      });
      $(".rollbackup-label").click(function (event) {
        event.preventDefault();
        var $contentType = $(this).parent(".content-type"),
          hideElements = parseInt(spotData.maxListCount, 10) + 1;

        // hide the back link
        $contentType.removeClass("expanded");
        $(this).removeClass("show");
        // hide links within current content type
        $(".file-link:nth-child(n+".concat(hideElements, ")"), $contentType).addClass("file-link-hidden");
        // display view all link
        $contentType.children(".viewallitems-label").addClass("show");
        selectedContentType = "";
        updateBrowserHistory();
        updateLayout();
      });

      // This only works on mobile
      $(".content-type-expander").click(function () {
        var $contentType = $(this).parents(".content-type");
        if ($contentType.hasClass("expanded")) {
          // When the box is open
          $contentType.removeClass("expanded");
          selectedContentType = "";
        } else {
          // When the box is closed
          $contentType.addClass("expanded");
          selectedContentType = $contentType.attr("id").split("content-type-")[1];
          contentTypeShowAll();
        }
        updateBrowserHistory();
        updateLayout();
      });
      $(".lighbox-close").click(function () {
        hideLightbox($(this).parents(".detail-lightbox"));
      });

      // JQuery hook to close the lightbox
      $(".lighbox-close").on("resource-center:hide-lightbox", function () {
        hideLightbox($(this).parents(".detail-lightbox"));
      });
    };
    DGS.Resizer.getInstance().register(function () {
      var $detailLightboxContainer = $(".detail-lightbox .inner-container");
      $detailLightboxContainer.removeAttr("style");
    });
    DGS.Components.DataHierarchySpot.getInstance().register("resource-center", function (data, $spot) {
      spotData = data;
      if (selectedFilters.length === 0) {
        // Use Sitecore predefined filters (if any) and update URL
        try {
          selectedFilters = JSON.parse(spotData.filtersPredefined);
          updateBrowserHistory();
        } catch (e) {
          // No predefined filter or invalid json
        }
      }
      spotData.maxListCount = spotData.maxListCount > 0 ? spotData.maxListCount : Number.MAX_SAFE_INTEGER;
      itemLayoutModel = $spot.hasClass("grid-layout") ? GRID : LIST;
      return {
        mainContainer: $(".component-content", $spot),
        sections: {
          desktop: {
            // Can this be changed to be MAIN or something not device specific?
            containers: [{
              className: "filters"
            }, {
              className: "resources"
            }]
          }
        }
      };
    }, function ($spot) {
      // show spinner
      buildLoader($(".component.resource-center"));
      DGS.Dictionary.getInstance().getTexts(dictionary, function () {
        // get resource center data
        $(".filters > .inner-container", $spot).empty();
        $(".resources > .inner-container", $spot).empty();
        getData(function () {
          // hide spinner
          removeLoader($(".component.resource-center"));
          buildStuff($spot);
        });
        DGS.Resizer.getInstance().register(function () {
          var newLayoutType = DGS.getLayoutType() === DGS.MOBILE_LAYOUT ? DGS.MOBILE_LAYOUT : DGS.DESKTOP_LAYOUT;
          if (newLayoutType !== currentLayoutType) {
            currentLayoutType = newLayoutType; // To prevent too many calls?
          }
        });
      }, theLang);
    });
  });
})(jQuery, window.DGS);