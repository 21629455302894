require('core-js');require('regenerator-runtime/runtime');// FOUNDATION
import $ from "jquery";
window.jQuery = $;
window.$ = $;
import { TweenMax } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
require("../../../wdh-foundation/dgs/dgs-api.js");
require("../../../wdh-foundation/dgs/dgs-events.js");
require("@demant/megamenu");
require("../../../wdh-foundation/dgs-utils/dgs-utils.js");
require("@demant/burgermenu");
require("jquery-bridget");
require("masonry-layout");
require("jquery-nice-select/js/jquery.nice-select.min.js");
require("jquery-ui-dist/jquery-ui.min.js");
require("iframe-resizer");

// FEATURE

require("../../../wdh-feature/wdh-blog/src/js/wdh-categories.js");
require("../../corporate-template/js/project/main.js");
require("./header.js");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-a-data-hierarchy.js");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-appframe-component.js");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-hubspot.js");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-b-data-hierarchy-multi-step.js");
require("./feature/download-center.js");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-data-hierarchy-dataset.js");
require("../../corporate-template/js/feature/data-hierarchy-spot/component-data-hierarchy-spot-ui-elements.js");
require("../../corporate-template/js/feature/video-spot.js");
require("../../corporate-template/js/feature/cookies.js");
require("../../corporate-template/js/feature/form.js");
require("../../corporate-template/js/feature/iframe.js");
require("../../corporate-template/js/feature/image-spot.js");
require("../../corporate-template/js/feature/intro-banner.js");
require("../../corporate-template/js/feature/rich-text.js");
require("../../corporate-template/js/feature/search-base.js");
require("../../corporate-template/js/feature/search.js");
require("../../corporate-template/js/feature/submenu-point-spot.js");
require("./feature/text-image-spot");
require("../../corporate-template/js/feature/two-section-grid.js");
require("./feature/component-extranet.js");
require("./feature/component-news-archive-spot.js");
require("./feature/component-lattice-spot.js");
require("./feature/image-spot");
require("./feature/alignElements.js");
require("./feature/component-blog.js");
require("./feature/component-blog-list.js");
require("./feature/component-blog-related.js");
require("./feature/component-blog-author.js");
require("./feature/component-blog-latest-posts.js");
require("./feature/component-flexspot-video.js");
require("./feature/component-flexspot-pardot-form.js");
require("./feature/component-accordion-spot.js");
require("./main.js");
require("./intro-banners.js");
require("@demant/wdh-gdpr/lib/default.implementation.js");