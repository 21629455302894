(function ($) {
  var introBanners = $(".intro-banner");
  introBanners.each(function (index, item) {
    var textChildrenLength = $(item).find(".intro-banner-text").children().length;
    var linkChildrenLength = $(item).find(".intro-banner-link").children().length;
    if (textChildrenLength === 0 && linkChildrenLength === 0) {
      $(item).find(".intro-banner-content").remove();
    }
  });
})(jQuery, window.DGS);
DGS.Resizer.getInstance().register(function () {
  var introBanners = $(".intro-banner");
  introBanners.each(function (index, item) {
    var video = $(item).find(".intro-banner-video");
    var image = $(item).find(".intro-banner-image").find("img");
    if (video.length && image.length) {
      if ($(window).width() < 768) {
        image.show();
        video.hide();
      }
      if ($(window).width() >= 768) {
        image.hide();
        video.show();
      }
    }
  });
});