require("../../../corporate-template/js/feature/text-image-spot.js");
(function ($) {
  var windowWidth = $(window).width();
  var firstComponent = $('#content .component')[0];
  var pageImageSpots = $('.text-image-spot');
  if (windowWidth <= 768 && firstComponent.classList.contains('text-image-spot')) {
    $(firstComponent).css('padding-top', '0');
  }
  if (windowWidth <= 991 && pageImageSpots && pageImageSpots.length > 0) {
    pageImageSpots.each(function (index, spot) {
      if ($(spot).hasClass('text-background')) {
        var currentColor = $(spot).css('background-color');
        $(spot).css('background-color', 'transparent');
        $(spot).find('.text-image-spot-text').css({
          'backgroundColor': currentColor,
          'padding': '10px'
        });
      }
    });
  }
})(jQuery, window.DGS);