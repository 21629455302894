import { convertDate } from "../utils.js";
(function ($, DGS, moment) {
  "use strict";

  DGS.OnLoad.getInstance().register(function () {
    $(".component.blog-list").each(function () {
      $("#blog-list li", $(this)).each(function () {
        // Now change the date format
        var $newsDate = $(".news-date", $(this));
        // Moves the date to after the title
        $newsDate.insertAfter($(".news-title", $(this)), null);
      });
    });
    var newsDate = document.querySelectorAll(".bloglist .news-date");
    newsDate.forEach(function (newsDate) {
      var dateFormat = newsDate.textContent;
      var convertedDate = convertDate(dateFormat);
      newsDate.textContent = convertedDate;
    });
    var newsLink = document.querySelectorAll(".bloglist .news-link");
    newsLink.forEach(function (el) {
      el.querySelector("span").innerText = "Read article >";
    });
  });
  var articles = $(".blog-list ul#blog-list li");
  var blogList = $(".component-content.bloglist");
  $(".component.blog-list").addClass("pagination-ready");
  var url = new URL(window.location.href);
  var currentPage = Number(url.searchParams.get("page")) || 1;
  var maxArticlesPerPage = 6;
  var setUrlHistory = function setUrlHistory() {
    url.searchParams.has("page") ? url.searchParams.set("page", currentPage) : url.searchParams.append("page", currentPage);
    window.history.replaceState("bloglist-page", "", url.search);
  };
  var paginateArticles = function paginateArticles() {
    if (articles.length > maxArticlesPerPage) {
      articles.addClass("hide-from-list");
      for (var i = 0; i < maxArticlesPerPage * currentPage; i++) {
        $(articles[i]).removeClass("hide-from-list");
      }
    }
    if (maxArticlesPerPage * currentPage >= articles.length) {
      $(".addMoreButton").remove();
    }
  };
  var viewMoreButton = $('<button class="addMoreButton " type="button">Load more</button>');
  blogList.append(viewMoreButton);
  viewMoreButton.on("click", function (e) {
    e.preventDefault();
    currentPage += 1;
    setUrlHistory();
    paginateArticles();
  });
  paginateArticles();
  DGS.Resizer.getInstance().register(function () {
    var row = 1;
    if ($(window).width() > 1200) {
      row = 3;
    } else if ($(window).width() > 991) {
      row = 2;
    }
    DGS.alignElements(row, ".bloglist > #blog-list > li", [".news-title", ".news-text"]);
  });
})(jQuery, window.DGS, window.moment);