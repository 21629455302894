(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $(".extranet-request-account").each(function () {
      $(".heading", $(this)).insertBefore($(".description"), $(this));
    });
    $(".extranet-login").each(function () {
      $(".request-account", $(this)).insertAfter($(".description"), $(this));
    });
    $(".forgot-login input[type=submit]").addClass("btn-primary");
  });
})(jQuery);