import WDHImageSpot from "../../../../wdh-feature/wdh-image-spot/src/js/wdh-image-spot";
var imageSpot = new WDHImageSpot();
imageSpot.makeImageSpotClickable = true;
imageSpot.run();
var imageSpotElements = $(".image-spot-element");
$(".image-spot-elements .image-spot-element-link a").addClass("btn btn-primary");
imageSpotElements.each(function () {
  var _this = $(this);
  var linkElement = _this.find(".image-spot-element-link");
  if (!linkElement.length) {
    var anchore = _this.find("a").eq(0);
    var href = anchore.attr("href");
    var imageWrapper = _this.find(".image-spot-element-image").eq(0);
    if (anchore.length && imageWrapper.find("> img").length) {
      imageWrapper.wrap("<a href=\"".concat(href, "\"></a>"));
    }
  }
  var clickableImageSpot = document.querySelectorAll(".component.image-spot.clickable-style");
  clickableImageSpot.forEach(function (element) {
    var clickableImageSpotElements = element.querySelectorAll(".image-spot-element");
    clickableImageSpotElements.forEach(function (element) {
      var linkElements = element.querySelectorAll("a");
      var linkElement = linkElements[0];
      if (linkElements.length === 1) {
        linkElement.classList.add("link-hover");
        var link = linkElement.getAttribute("href");
        if (link) {
          element.addEventListener("click", function () {
            window.location.href = link;
          });
        }
      }
    });
  });
});

// Load more content - tile functionality
var paginatedImageSpots = document.querySelectorAll(".component.image-spot.paginate-content");
paginatedImageSpots.forEach(function (imageSpot) {
  var spotElements = imageSpot.querySelectorAll(".image-spot-element");
  spotElements.forEach(function (spotElement) {
    var textElement = spotElement.querySelector(".image-spot-element-text");
    var paragraphsArray = textElement.querySelectorAll("p");
    if (paragraphsArray.length > 6) {
      for (var i = 6; i < paragraphsArray.length; i++) {
        paragraphsArray[i].style.opacity = "0";
        paragraphsArray[i].style.display = "none";
      }
      var loadMoreButton = document.createElement("span");
      loadMoreButton.classList.add("load-more-button");
      var loadMoreContent = document.createTextNode("Load more >");
      loadMoreButton.appendChild(loadMoreContent);
      textElement.insertBefore(loadMoreButton, paragraphsArray[6]);
      loadMoreButton.addEventListener("click", function () {
        var foldedHeight = textElement.offsetHeight;
        textElement.style.maxHeight = "".concat(foldedHeight, "px");
        paragraphsArray.forEach(function (post) {
          if (post.style.display === "none") post.style.display = "block";
        });
        textElement.removeChild(loadMoreButton);
        var expandedHeight = textElement.scrollHeight;
        textElement.style.maxHeight = "unset";
        textElement.style.height = "".concat(expandedHeight, "px");
        paragraphsArray.forEach(function (post) {
          if (post.style.opacity === "0") post.style.opacity = "1";
        });
      });
    }
  });
});