(function ($, DGS) {
  var burgerMenuConfig = {
    expanderSelector: ".header-mobile-top .nav-expander",
    menuItemsClass: "text-left",
    groupTwoLastLevels: false
  };
  DGS.OnLoad.getInstance().register(function () {
    DGS.DESKTOP_LAYOUT = "desktop";
    DGS.TABLET_LAYOUT = "tablet";
    DGS.MOBILE_LAYOUT = "mobile";
    DGS.getLayoutType = function () {
      if (window.matchMedia("(max-width: 1260px)").matches) {
        return DGS.MOBILE_LAYOUT;
      }
      return DGS.DESKTOP_LAYOUT;
    };
    var sectionShown,
      $logo = $("#header .image"),
      $menu = $("#header .navigation"),
      $search = $("#header .search-field-spot"),
      $mainContainer = $("#wrapper > #header"),
      $languageSelector = $("#header .language-selector-spot"),
      $extranetName = $("#header .profile-user"),
      $extranetMenu = $("#header .extranet-menu-anonymous"),
      $extranetMenuIn = $("#header .extranet-menu-authorized"),
      headerSettings = {
        mainContainer: $mainContainer,
        determineSectionCallback: function determineSectionCallback(section) {
          sectionShown = section;
          return null;
        },
        sections: {
          mobile: {
            containers: [{
              className: "header-mobile-top",
              components: [$logo, $search, $('<div class="nav-expander"><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span></div>'), $languageSelector, $extranetMenu, $extranetName, $extranetMenuIn]
            }, {
              className: "header-mobile-menu",
              components: [$menu]
            }]
          },
          desktop: {
            containers: [{
              className: "header-desktop-top",
              containers: [{
                className: "row-1",
                components: [$extranetMenu, $extranetMenuIn, $extranetName, $search, $menu, $logo, $languageSelector]
              }]
            }]
          }
        }
      };

    // BUILD MARKUP
    DGS.Builder.build(headerSettings);
    DGS.Event.getInstance().on(DGS.Events.BUILDER_DONE, function (settings) {
      if (settings !== headerSettings) return; // ONLY RUN POST SETUP IF IT'S THE RIGHT BUILDER

      $("#header").addClass("animate");
      $("#header #myNav").addClass("hidden");
      $(".desktop-section li.user-menu").insertBefore($(".desktop-section .search-field-spot"));
      $("#header .component.image:last-child").insertBefore($("#header .desktop-section .component.navigation"));

      // SETUP BURGER MENU
      var backButton = '<div class="nav-back">Back</div>';
      $(".mobile-section .navigation").before(backButton);
      $(".mobile-section .navigation .component-content > ul").burgerMenu(burgerMenuConfig);
      // REGISTER RESIZER HANDLER
      DGS.Resizer.getInstance().register(function () {
        // ADJUST CONTENT SECTION BY ADDING BOTTOM MARGIN TO HEADER
        var headerHeight = 0;
        if (sectionShown === DGS.DESKTOP_LAYOUT) {
          headerHeight = $("#header .desktop-section > .inner-container").outerHeight();
        } else if (sectionShown === DGS.MOBILE_LAYOUT) {
          headerHeight = $("#header .header-mobile-top").outerHeight();
          $(".dgs-burger-menu").css("top", headerHeight);
          $(".nav-back").css("top", headerHeight);
        }
        $("#header").css("margin-bottom", headerHeight);
      });
    });
    // Prevent browser from showing tooltip with title attributes
    var $attr = $("[title]");
    $attr.hover(function () {
      var title = $(this).attr("title");
      $(this).attr("temp-title", title);
      $(this).attr("title", "");
    });
    $attr.click(function () {
      var title = $(this).attr("temp-title");
      $(this).attr("title", title);
    });
    // Prevent browser from showing tooltip with title attributes - end
  });
  $(".extranet-menu-authorized").click(function () {
    if ($(".extranet-menu-authorized")[0]) {
      window.location.href = "/extranet/login/";
    } else {
      window.location.href = "/contact/";
    }
  });
  var loginContent = $(".extranet-menu-anonymous a");
  if (loginContent && $(loginContent).text() !== "") {
    $(loginContent).text("");
  }
  function closeNav() {
    $("#myNav.overlay").addClass("hidden");
  }
  window.closeNav = closeNav;
  // chnage profile name to account
  document.querySelector("#header .profile-user").innerHTML = "Account";
})(jQuery, window.DGS);